import { lazy } from "react";
const Default = lazy(() => import("pages/default"));
const News = lazy(() => import("pages/news"));
const Feedback = lazy(() => import("pages/feedbacks"));
const Evolutions = lazy(() => import("pages/evolutions"));
const Dealers = lazy(() => import("pages/dealers"));
const Galleries = lazy(() => import("pages/galleries"));
const Videos = lazy(() => import("pages/videos"));
const Partners = lazy(() => import("pages/partners"));
const Admin = lazy(() => import("pages/admin"));
const Login = lazy(() => import("pages/login"));
const NotFound = lazy(() => import("pages/notFound"));
const LocalizationPanel = lazy(() => import("pages/localizationPanel"));

export interface IRoute {
  path: string;
  access?: string[] | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [
  {
    path: "/",
    access: ["admin", "user"],
    title: "Xush kelibsiz!",
    element: <Default />,
  },
  {
    path: "/news",
    access: ["admin"],
    title: "Yangiliklar",
    element: <News />,
  },
  {
    path: "/feedbacks",
    access: ["admin"],
    title: "Fikrlar",
    element: <Feedback />,
  },
  {
    path: "/evolutions",
    access: ["admin"],
    title: "Evolyutsiya",
    element: <Evolutions />,
  },
  {
    path: "/galleries",
    access: ["admin"],
    title: "Gallereya",
    element: <Galleries />,
  },
  {
    path: "/videos",
    access: ["admin"],
    title: "Video",
    element: <Videos />,
  },
  {
    path: "/partners",
    access: ["admin"],
    title: "Hamkorlar",
    element: <Partners />,
  },
  {
    path: "/dealers",
    access: ["admin"],
    title: "Dilerlar",
    element: <Dealers />,
  },
  {
    path: "/profile",
    access: ["admin"],
    title: "Profil",
    element: <Admin />,
  },
  {
    path: "/translations",
    access: ["admin"],
    title: "Tarjimalar",
    element: <LocalizationPanel />,
  },
  {
    path: "*",
    access: ["admin"],
    title: "Aniqlanmagan sahifa",
    element: <NotFound />,
  },
];

const publicRoutes: IRoute[] = [
  // {
  //   path: "/login",
  //   access: [],
  //   title: "Login",
  //   element: <Login />,
  // },
];

export { privateRoutes, publicRoutes };
